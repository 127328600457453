import { graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import React from 'react';
import { Coffee } from '../components/Coffee';
import Layout from '../components/layout';
import Seo from '../components/seo';

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="About"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <h1>About</h1>
        <p>
          Hi all, I'm Nick Scialli, a software engineer currently working at the
          U.S. Digital Service. This blog mostly consists of web development
          tutorials I have written over time with some opinion pieces
          interspersed.
        </p>
        <Coffee from="About Page" />
        <h2>Contact</h2>
        <p>
          I'm always interested in hearing from folks who want to work with me.
          I'm especially interested in any writing or teaching opportunities you
          have in mind!
        </p>
        <p>
          If you'd like to send me an email, click the button below to reveal my
          email address (sorry for the obfuscation, I'm just trying to trick the
          spambots).
        </p>
        {this.state.show ? (
          <p style={{ padding: '0.75em', fontWeight: 'bold' }}>
            nickscialli@gmail.com
          </p>
        ) : (
          <button
            onClick={() => {
              trackCustomEvent({
                category: 'Contact',
                action: 'Clicked Show Email',
                label: 'Show Email Button',
                value: 1,
              });
              this.setState({ show: true });
            }}
            style={{ padding: '0.5em 1em', marginBottom: '2em' }}
          >
            Show Nick's Email Address
          </button>
        )}

        <p style={{ marginBottom: '4em' }}>
          Thanks for getting in touch with me!
        </p>
      </Layout>
    );
  }
}

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
