import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import React from 'react';

export const Coffee = ({ style, from }) => {
  return (
    <p style={style}>
      If you'd like to support this blog by{' '}
      <a
        href="https://ko-fi.com/nickscialli"
        onClick={() => {
          trackCustomEvent({
            category: 'Coffee',
            action: 'Clicked Ko-fi Link',
            label: `Coffee from ${from}`,
            value: 1,
          });
        }}
      >
        buying me a coffee
      </a>{' '}
      I'd really appreciate it!
    </p>
  );
};
